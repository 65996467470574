import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  pageType: 'core',
  pageName: 'home-duplicated',
  parentPageInfos: null,
  title: 'Back Market : Passez au meilleur de la tech reconditionnée.',
  badge: '',
  titleSeo: 'Passez au meilleur de la tech reconditionnée.',
  titleBreadcrumb: 'Home',
  banner: {},
  meta: {
    title: 'Passez au meilleur de la tech reconditionnée.',
    description:
      'Profitez de produits reconditionnés sur Back Market, jusqu’à 70% moins cher que le neuf ! Livraison standard offerte - Garantie 12 mois contractuelle minimum - Paiement sécurisé en 3 ou 4X',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/7afded1c56327aa0f5db6a5bb2785853/SEO-Website-Thumbnail.png',
      srcMobile: '',
      alt: '',
      width: 516,
      height: 258,
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-fr',
      },
      {
        country: 'BE',
        locale: 'fr-be',
      },
      {
        country: 'ES',
        locale: 'es-es',
      },
      {
        country: 'DE',
        locale: 'de-de',
      },
      {
        country: 'IT',
        locale: 'it-it',
      },
      {
        country: 'GB',
        locale: 'en-gb',
      },
      {
        country: 'AT',
        locale: 'de-at',
      },
      {
        country: 'NL',
        locale: 'nl-nl',
      },
      {
        country: 'US',
        locale: 'en-us',
      },
      {
        country: 'FI',
        locale: 'fi-fi',
      },
      {
        country: 'PT',
        locale: 'pt-pt',
      },
      {
        country: 'IE',
        locale: 'en-ie',
      },
      {
        country: 'GR',
        locale: 'el-gr',
      },
      {
        country: 'SK',
        locale: 'sk-sk',
      },
      {
        country: 'SE',
        locale: 'sv-se',
      },
      {
        country: 'JP',
        locale: 'ja-jp',
      },
      {
        country: 'AU',
        locale: 'en-au',
      },
    ],
  },
  textSeo: null,
  blocks: [
    {
      id: '53kOoh3WISB2LimhTZne01',
      type: 'Carousel',
      hiddenDevices: [],
      props: {
        slides: [
          {
            id: '3RVzJ1HbLK4jl2DP7stIZb',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5z0JiAblhRIOpimxs70vrG/010563a91662ae16c438f7642af0ca4e/Dyson-Airstrait_HP-Desktop_FR.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4HuxxbyNvx7BQv7N9ExE4v/8cc5cd94d289bbbd8dce3d151d30e723/Dyson-Airstrait_HP-Mobile_FR.jpg',
              alt: '',
              width: 2880,
              height: 686,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://www.backmarket.fr/fr-fr/p/dysontm-airstraittm-nickelcuivre/d468d00d-f602-4a72-9020-ba46cb5cc52a',
            },
          },
          {
            id: '5ot2fyLd9EUkjAy1BElP0J',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1A90qyVyJiMsmmIBsoznSm/f6e71bef4d6f4561fd455fb761c94790/DN_HP-Banners_2_desktop_FR__2_.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1zjnKoYb7KOWQ7vEqHSWny/fe08f5e4f6453bf330eccea2d0bc3abf/DN_HP-Banners_2_mobile_FR.jpg',
              alt: 'FR - iPhones x Downgrade Now',
              width: 2880,
              height: 686,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://www.backmarket.fr/fr-fr/l/iphone-reconditionne/aabc736a-cb66-4ac0-a3b7-0f449781ed39',
            },
          },
          {
            id: '3XcqmwdJ2nCv9zZJrQYaDy',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5pVfSwblyfbvHpbr6Wf8Ou/e2869267d4415ed9df9c7c44f8fa4d8c/0b35958a-6cb9-4fb3-95c8-0effb120d77e.jpeg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6xLnSgQ1sObANTDPJ8AJI2/f094c05499fb2ccc86a0052073d8ff97/37cfefec-961e-4716-bd37-81bf15adc51b.jpeg',
              alt: 'FR - MacBook x Generic',
              width: 2880,
              height: 686,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://www.backmarket.fr/fr-fr/l/serie-macbook-m1/28f79b52-b5c8-4b2b-8325-e61d404808f0',
            },
          },
          {
            id: 'MB07P5V3ZLIKn3x8h7Vss',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4SETqwVYNArwmYeW9Qw36m/a0535b6e9c4c124665853e4e77e9cc0e/BT_HP_Desktop_July3__1_.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3AgbzUgB32VjwKqidPFn86/e4c19c9547abd860539bcf1cdd450fe8/BT_HP_Mobile_July3__3___1_.jpg',
              alt: 'FR - Bouygues v2 AB test',
              width: 2880,
              height: 686,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://www.backmarket.fr/fr-fr/service/bouygues-telecom',
            },
          },
        ],
      },
    },
    {
      id: '7BjAQJlqmYU3Y2xjgWIfTF',
      type: 'SectionBanner',
      hiddenDevices: [],
      props: {
        title: 'La circularité recommence ici.',
        description:
          'Achetez et revendez vos appareils à des pros du reconditionné.',
      },
    },
    {
      id: '5AU2jvWXt9VrKGPnwdE5R7',
      type: 'Reinsurance',
      hiddenDevices: [],
      props: {
        blocks: [
          {
            id: 'TdTTpopMIq41KRTBfdc54',
            title: 'Reconditionné par des pros',
            icon: 'IconOfficial',
            link: {
              href: 'https://backmarket.fr/fr-fr/quality',
            },
            linkPosition: 'title',
          },
          {
            id: '311rqjLhYALnNtQ7Tvrhmc',
            title: 'Reprise au meilleur prix',
            icon: 'IconPriceUpdate',
            link: {
              href: 'https://www.backmarket.fr/fr-fr/buyback/home',
            },
            linkPosition: 'title',
          },
          {
            id: 'FOnP42jyNVM0H968yuDsN',
            title: 'Paiement en plusieurs fois',
            icon: 'IconCreditCard',
            link: {
              href: 'https://help.backmarket.com/hc/fr-fr/articles/360026643854-Quels-sont-les-moyens-de-paiement-accept%C3%A9s-sur-Back-Market',
            },
            linkPosition: 'title',
          },
          {
            id: '6qvmF6LXHuvsEVi0IzkWZc',
            title: "Fonctions bonus dans l'app",
            icon: 'IconSmartphone',
            link: {
              href: 'https://backmarket.onelink.me/j4lA/v0dxsy5i',
            },
            linkPosition: 'title',
          },
        ],
        mobileScreenCols: 2,
        hasBackground: true,
        theme: 'white',
      },
    },

    {
      id: '4WT7tIoIluVRSlcGCOVDvi',
      type: 'ModularCardsCarousel',
      hiddenDevices: [],
      props: {
        title: 'Nos meilleures ventes',
        size: 'small',
        cardsHavePadding: false,
        layout: 'grid',
        mobileScreenColumns: 2,
        cards: [
          {
            id: '1zYH0z3XAa4Mp85VVrpy3l',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '2pOBq7z701BNBAvMketl8Y',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5QiFf3IKAPnyvmJAbKAkL4/2dc6de2a6b4bec74e9abde208ead6b13/Phone_HP_Category_Smartphone_desktop.jpg',
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/5pOFam9DGZq71N9Mjp6oq8/85a28dd521ea30de41152433dd58355e/Phone_HP_Category_Smartphone_mobile.jpg',
                        alt: 'iPhones',
                        width: 536,
                        height: 360,
                        widthMobile: 400,
                        heightMobile: 400,
                      },
                      link: {
                        href: 'https://www.backmarket.fr/fr-fr/l/iphone-reconditionne/aabc736a-cb66-4ac0-a3b7-0f449781ed39',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: '1xpEND1XrxFgQ91syAl0uG',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      richText: {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: 'iPhone',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.HEADING_3,
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: '',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                          },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
          {
            id: '7HUTKtA2zWfs3mACJpfddM',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '61kZUoLlOwyH1ROV2gvNoA',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5GjoGJCqWVngJRShjjG7a/3ec1d9e76d95c4afc68a9dbd9b4212e9/HP_Category_Laptop_desktop.jpg',
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/4W39yhNXfMespBqgSIXmLQ/d77262006ca5bb55dc8b2538e5789502/HP_Category_Laptop_mobile.jpg',
                        alt: 'Macbook',
                        width: 536,
                        height: 360,
                        widthMobile: 400,
                        heightMobile: 400,
                      },
                      link: {
                        href: 'https://www.backmarket.fr/fr-fr/l/macbook-reconditionne/297f69c7-b41c-40dd-aa9b-93ab067eb691',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: 'djIs4oEAt3oPrbpMPtR0V',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      richText: {
                        nodeType: BLOCKS.DOCUMENT,
                        data: {},
                        content: [
                          {
                            nodeType: BLOCKS.HEADING_3,
                            data: {},
                            content: [
                              {
                                nodeType: 'text',
                                value: 'MacBook',
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: BLOCKS.PARAGRAPH,
                            data: {},
                            content: [
                              {
                                nodeType: 'text',
                                value: '',
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
          {
            id: '56vXSPx6GQrsMIwGVeHDra',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '6hPsHaAN7PlQw69XaKmaFl',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/4hFoTgvuPtlS2xGUdtLsRy/9521bba715f745a21789f3990e04be44/HP_Category_Tablet_desktop.jpg',
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/2AmON8mdD9dHD2bp0521LV/4c9923529c50fbe1a7e2711a7db0e783/HP_Category_Tablet_mobile.jpg',
                        alt: 'iPad',
                        width: 536,
                        height: 360,
                        widthMobile: 400,
                        heightMobile: 400,
                      },
                      link: {
                        href: 'https://www.backmarket.fr/fr-fr/l/ipad/6053d9e8-2eaa-4971-9b6e-79b8a16e4dee',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: '3Q9RxFV3I6BjaXDxKPejek',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      richText: {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: 'iPad',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.HEADING_3,
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: '',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                          },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
          {
            id: '5extb8eRH93haqoi2Xy1Sy',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '36FNOZ3yPaynlWCeIxoLoZ',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5Q6Fw32r8NELQcVLLxxqim/ed2b603915c26f6fdcd2194dca67de25/Phone_HP_Category_Gaming_desktop.jpg',
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/7opuBWTtcbitW7A2tcaLHx/3621390daef1621084b77f5654545168/Phone_HP_Category_Gaming_mobile.jpg',
                        alt: 'Gaming console',
                        width: 536,
                        height: 360,
                        widthMobile: 400,
                        heightMobile: 400,
                      },
                      link: {
                        href: 'https://www.backmarket.fr/fr-fr/l/consoles-et-jeux-video/0a092eda-8a90-4b60-85bd-8a3123b2fd84',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: '6IhsS5vojbeiB2QbMKzFkd',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      richText: {
                        nodeType: BLOCKS.DOCUMENT,
                        data: {},
                        content: [
                          {
                            nodeType: BLOCKS.HEADING_3,
                            data: {},
                            content: [
                              {
                                nodeType: 'text',
                                value: 'Console de jeux',
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: BLOCKS.PARAGRAPH,
                            data: {},
                            content: [
                              {
                                nodeType: 'text',
                                value: '',
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
        ],
      },
    },

    {
      id: '3h1AHA7VEksw15Iore0H9a',
      type: 'Carousel',
      hiddenDevices: [],
      props: {
        slides: [
          {
            id: '1wRUWIqliNb3AwdCByUT22',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4gSQe7IF303sjsH71XsWM3/fa20b39fdc5d87574b8e35d2eb47f069/Slider-1-desktop-noCTA-FR-x2.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3cvd6zNtIUXNXwW5gb0xqs/8be21c79230e31aea6381519117f48d4/Slider-1-mobile-FR-noCTA.jpg',
              alt: 'Refurbished, better than used',
              width: 2240,
              height: 800,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://www.backmarket.fr/fr-fr/c/news/used-vs-refurbished-smartphone',
            },
          },
          {
            id: '6zg6GkbI3nVwNiCbKt7o7m',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5XoWhUKhH4w8CoeR6VrKif/ba522857711ed78084cea327f49f9750/Slider-2-desktop-FR-x2.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3SmRsumTmHSbe9QFD75EZL/4f5d28a3cbbab7624ee3f6f84c9b87f1/Slider-2-mobile-FR.jpg',
              alt: 'Back Market is better than new',
              width: 2240,
              height: 800,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://www.backmarket.fr/fr-fr/c/news/used-vs-refurbished-smartphone',
            },
          },
          {
            id: '2EIy7ivMSU26qOuHExvYyv',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2T9ErDOpAaJ1Hi58z9pzSn/97d967992ff3df3dccea2a476f77fdbd/Slider-3-desktop-FR-x2.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5KVuOyciLi1EKK21i1dtVq/fb085dec90cbe820dda0eb5561e3c831/Slider-3-mobile-FR.jpg',
              alt: 'What is Verified Refurbished',
              width: 2240,
              height: 800,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://backmarket.fr/fr-fr/quality',
            },
          },
          {
            id: 'apWMpQrmWRaaSGQrAICyU',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/75ngmt4iPow0sUYPUCdP7b/2d8bef0bfa00cf085a7b0a90dc827f9c/Slider-4-desktop-FR-x2.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4iilzbyxiYc1sXWAvg1BwT/32276379b150eaba94c1c9630899aab4/Slider-4-mobile-FR.jpg',
              alt: "We've prevented over 1 milion tons of CO2 emissions",
              width: 2240,
              height: 800,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://backmarket.fr/fr-fr/impact/fr-fr/rapport-impact-2024',
            },
          },
          {
            id: '3MwwqfUQI5fnb5JVSskT8u',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5YDv2y0xq8QaRIp3v6iXpD/5bc7f17adcff39eb5d26df66c6c8e898/Slider-5-desktop-FR-x2.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4rQLaohmk9wFHk6ZfdAU9q/57755f0694ce362e08f81dc0ec3af7c0/Slider-5-mobile-FR.jpg',
              alt: '',
              width: 2240,
              height: 800,
              widthMobile: 750,
              heightMobile: 750,
            },
          },
        ],
        isFullWidth: false,
      },
    },

    {
      id: '7tVOp6ou724Hps00wbS6T8',
      type: 'SectionBanner',
      hiddenDevices: [],
      props: {
        title: 'Une alternative plus durable que le neuf.',
        description: "Et si on en faisait plus avec ce qu'on a déjà ?",
        button: {
          variant: 'primary',
          size: 'normal',
          ctaLink: {
            label: 'Voir pourquoi',
            link: {
              href: 'https://backmarket.fr/fr-fr/impact/fr-fr/rapport-impact-2024',
            },
          },
        },
      },
    },
    {
      id: '4Bjfvs9LAuVrMNksH3VJM4',
      type: 'Media',
      hiddenDevices: [],
      props: {
        image: {
          src: 'https://images.ctfassets.net/mmeshd7gafk1/3DDzO9xDvFZ1GVyoHjpNwz/2e552ec1b0b0d1b2263f90b6460e4ef2/Desktop___FR.png',
          srcMobile:
            'https://images.ctfassets.net/mmeshd7gafk1/4JfaBEO8f6W2oboc4ShUBc/89fe86f6e29d14216e79ff965a043079/Mweb___FR.png',
          alt: 'trade-in',
          width: 2244,
          height: 648,
          widthMobile: 780,
          heightMobile: 872,
        },
        link: {
          href: 'https://www.backmarket.fr/fr-fr/buyback/home',
        },
      },
    },
    {
      id: '6B9OXXWCd6Y4ZBcGZGbX9R',
      type: 'EmailCollector',
      hiddenDevices: [],
      props: {
        title: 'Vous lâchez votre mail. On lâche nos bons plans.',
        text: "C'est le principe d'une newsletter.",
        sectionBannerMonochromeBackground: {
          theme: 'purple',
        },
        buttonLabel: "S'inscrire",
        checkboxLabel:
          'Je veux recevoir des mails de Back Market (mais seulement les meilleurs).',
      },
    },
    {
      id: '4jCpWAoNAmqO8C15Q93jfV',
      type: 'VideosBlock',
      hiddenDevices: [],
      props: {
        title: 'En tech à tête : nos experts vous répondent.',
        videos: [
          {
            id: '7MtmORce57ygHK1XKVDVNd',
            video: {
              title: 'Les fausses bonnes idées pour votre smartphone',
              file: {
                url: '//videos.ctfassets.net/mmeshd7gafk1/2F8vQZQcZ0L16gARqrJNTQ/f9027e07f64959f745ab34ac31ef1e1b/Vertical_FR_Myths.mp4',
                details: {
                  size: 171730438,
                },
                fileName: 'Vertical FR Myths.mp4',
                contentType: 'video/mp4',
              },
            },
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6cf11kKyNQm5wKvTILCI4P/ea71742197a6573bde3a96f2be0f508b/Myths_about_your_tech_-_Thumbnail_-_FR.jpg',
              srcMobile: '',
              alt: 'FR - tech tips #7 thumbnail',
              width: 256,
              height: 455,
            },
          },
          {
            id: '6GnJ1l8lUjOgxdh2VksDNV',
            video: {
              title: 'Comment prendre soin de la batterie de son iPhone',
              file: {
                url: '//videos.ctfassets.net/mmeshd7gafk1/a80Gdcf6m4lUIET2drxOI/ad67f4cc609cfe258aa52ebb4c44e4e7/BATTERY-FR-9_16.mp4',
                details: {
                  size: 367441331,
                },
                fileName: 'BATTERY-FR-9_16.mp4',
                contentType: 'video/mp4',
              },
            },
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4sm8BKy2CGvYI4Xlzx4MiG/2ed061dcbb97f4d707d01fa8804e5bec/Thumbnail-9_16-FR.jpg',
              srcMobile: '',
              alt: 'FR - Tech tips #1 thumbnail',
              width: 1080,
              height: 1920,
            },
          },
          {
            id: '5v3zsvY7AjOz9wejkX827P',
            video: {
              title: 'Quel iPad choisir ?',
              file: {
                url: '//videos.ctfassets.net/mmeshd7gafk1/1EfNJSuLBx4ZuNixY7ePBH/24be0e392f658051dab806e09a6c2487/IPAD-FR.mp4',
                details: {
                  size: 274712381,
                },
                fileName: 'IPAD-FR.mp4',
                contentType: 'video/mp4',
              },
            },
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/39Jzn96vsIJc4SjgDmE5dO/390fd72594b62bfa4e1ccab711a4dcde/VDEF-Thumbail-verticale-FR.jpg',
              srcMobile: '',
              alt: 'FR - Tech tips #2 thumbnail',
              width: 256,
              height: 455,
            },
          },
          {
            id: '192rMg1yhzQMzg5sCvOvJK',
            video: {
              title: '3 conseils pour prendre soin de son ordinateur portable',
              file: {
                url: '//videos.ctfassets.net/mmeshd7gafk1/7aaZIr01CRgQQ6KkoCzt0J/d785658b162727212513c0edad882d87/3_TIPS-vertical-FR-ss.mp4',
                details: {
                  size: 332361166,
                },
                fileName: '3 TIPS-vertical-FR-ss.mp4',
                contentType: 'video/mp4',
              },
            },
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6L3Ksq2WuBbF8BfUOJGCBt/4daf0ed791dcd4462ef552797f0ecf2e/Tips_for_laptops_-_Thumbnail_-_FR.jpg',
              srcMobile: '',
              alt: 'FR - tech tips #6 thumbnail',
              width: 256,
              height: 455,
            },
          },
          {
            id: '3SmZr6Uebbp1IxrPI7SVHW',
            video: {
              title: 'Devez-vous changer la batterie de votre smartphone ?',
              file: {
                url: '//videos.ctfassets.net/mmeshd7gafk1/6TcakIRDCzM7Kx9UvxeiBO/81916a42d2687253bb812e5f9b9404f5/NEW-BATTERY-FR-verticale.mp4',
                details: {
                  size: 288756138,
                },
                fileName: 'NEW-BATTERY-FR-verticale.mp4',
                contentType: 'video/mp4',
              },
            },
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3v5HjltBR4a4SwogvAybgW/4e3008effdab0b59c006eef0c1380c6b/NEW-BATTERY-FR-verticale-thumbnail.jpg',
              srcMobile: '',
              alt: 'FR - Tech tips #3 thumbnail',
              width: 256,
              height: 455,
            },
          },
          {
            id: '4FYQy9fwRNIwOi243UnzaH',
            video: {
              title: 'Quel processeur choisir pour son MacBook ?',
              file: {
                url: '//videos.ctfassets.net/mmeshd7gafk1/24A2EoUITcAVXYZz9gNhKj/0e5d9abb39aea2c73c946a442621b799/PROCESSEUR-FR-VERTICALE.mp4',
                details: {
                  size: 317053051,
                },
                fileName: 'PROCESSEUR-FR-VERTICALE.mp4',
                contentType: 'video/mp4',
              },
            },
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4c8hm8pVm6dlXRZRRleaAv/a28cdea9c4c2b8ced3fdefdd85a5246b/PROCESSOR-FR-thumbnail-9_16.jpg',
              srcMobile: '',
              alt: 'FR - Tech tips #4 thumbail',
              width: 256,
              height: 455,
            },
          },
        ],
      },
    },
    {
      id: '7beXqjPZhm0x7tG7sN8vUx',
      type: 'ArticleCardsList',
      hiddenDevices: [],
      props: {
        title: 'Une alternative plus durable que le neuf.',
        guides: [
          {
            id: 'RxKOErzL2V27QnBrUjeCj',
            category: 'news',
            translatedCategory: 'News',
            title:
              'Back Market a évité l’émission d’un million de tonnes de CO2',
            text: 'Back Market limite-t-elle vraiment les dégâts environnementaux ? Absolument, grâce aux achats que vous réalisez.',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/65c185e88b7afe68b5820f8ae58ac860-meta-526-276/2586e3d93ad4a0f40c415ac4fcd28dbb/Merci__C3_A0_vous_3.png',
              alt: 'Back Market a évité l’émission d’un million de tonnes de CO2',
            },
            link: {
              href: 'https://www.backmarket.fr/fr-fr/c/news/one-million',
            },
          },
          {
            id: '1k2AiuWMTV81DbexolawSd',
            category: 'news',
            translatedCategory: 'News',
            title: 'L’impact environnemental du reconditionné.',
            text: "Le reconditionné et la planète, ça donne quoi ? Moins cher que le neuf, les produits électroniques reconditionnés ont un impact clair sur nos économies. Mais désormais, on a aussi la preuve scientifique qu'ils aident à préserver notre planète.",
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/JDfxfVfw7FPAID4NoZaIV/b69f0e5366947a81f59b9ad0e4139148/FR_environmental_banner_-_Thumbnail_.jpg',
              alt: 'L’impact environnemental du reconditionné.',
            },
            link: {
              href: 'https://www.backmarket.fr/fr-fr/c/news/impact-of-refurbished-on-environment',
            },
          },
          {
            id: '4zXpVNNUfacmSJ2v2MHI5L',
            category: 'news',
            translatedCategory: 'News',
            title:
              'Neuf v.s. reconditionné : l’impact environnemental de l’industrie tech.',
            text: "La production et la fin de vie de nos smartphones, ordinateurs et autres bijoux technologiques ont un impact très lourd sur l'environnement, qui tend à être un peu oublié.",
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/04c5ceef3606a6efa3f9d7a81679968e-meta-526-276/ad9d6f60318d7b3e64d4eaad9b0cb3fb/FR.jpg',
              alt: 'Neuf v.s. reconditionné : l’impact environnemental de l’industrie tech.',
            },
            link: {
              href: 'https://www.backmarket.fr/fr-fr/c/news/used-vs-refurbished-smartphone',
            },
          },
          {
            id: '2IBZEJwDPIcfhadhKW3tQt',
            category: 'news',
            translatedCategory: 'News',
            title: 'On est certifié B Corp',
            text: 'Back Market a reçu le label B Corp.\nQue signifie le label B Corp et que signifie-t-il pour Back Market ?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7i91ud843pSY7S72mdGW0J/0df45821558c8445b0d3c4281a689cd2/Article_banner_-_Thumbnail_copie__1_.jpg',
              alt: 'On est certifié B Corp',
            },
            link: {
              href: 'https://www.backmarket.fr/fr-fr/c/news/bcorp-status',
            },
          },
          {
            id: '3FR0yUtW5ssEdVWNgI6J1V',
            category: 'events',
            translatedCategory: 'Evènements',
            title: 'Back Market - L’impact du ewaste',
            text: 'Aujourd’hui les déchets d’équipement électriques et électroniques (DEEE) représentent un défi méconnu du grand public. Peu d’entre nous savent ce que deviennent nos vieux téléphones ou réfrigérateurs.  Nous consommons, puis jetons nos appareils électroniques à un rythme que nous ne pouvons même pas suivre. En France on estime que seulement 45% de nos déchets électroniques sont collectés, quant à peine 17,4% sont recyclés à l’échelle mondiale.',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/0a2182887aeacf2c0a836b6fbf8818b6-meta-526-276/1780d55070cae8a60d72b68034c260a1/image-1.png',
              alt: 'Back Market - L’impact du ewaste',
            },
            link: {
              href: 'https://www.backmarket.fr/fr-fr/c/events/the-impact-of-e-waste',
            },
          },
        ],
      },
    },
    {
      id: '67psa5vEJbFrOxHzlgnlP6',
      type: 'Buttons',
      hiddenDevices: [],
      props: {
        primary: {
          link: {
            href: 'https://www.backmarket.fr/fr-fr/back-stories/home',
          },
          label: 'Voir plus',
        },
      },
    },
    {
      id: '5YdjjD1IYCgLkP9T5f8zSE',
      type: 'Faq',
      hiddenDevices: [],
      props: {
        title: 'Les 4 questions qu’on nous pose le plus souvent.',
        subtitle: undefined,
        questions: [
          {
            id: '1P4N4ifSalDP5z6yFCfGOq',
            title:
              "Pouvez-vous m'aider à recycler mon ancien téléphone ou d'autres appareils électroniques ?",
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Notre ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.fr/fr-fr/e/recycling',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'Service Recyclage',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value:
                        ' est là pour vous mettre en relation avec les bonnes personnes et vous simplifier cette démarche. Mais peut-être que votre article a encore de la valeur. Obtenez une estimation immédiate via notre ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.fr/fr-fr/buyback/home',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'Service Reprise',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value: ". En plus, les frais d'expédition sont offerts.",
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '1V0ejmkEIGAEeXNnXh2GrM',
            title:
              'Est-ce que je peux souscrire à un forfait mobile sur Back Market ?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        "Back Market et Bouygues Telecom s'associent pour vous proposer une ",
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.fr/fr-fr/service/bouygues-telecom',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'offre Mobile + Forfait',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value:
                        ' avec des réductions et des forfaits exclusifs. Quelque chose de beau et malin. Comme vous.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '1kgDO3QU3qPLNQBZJzptD7',
            title: 'Est-ce que je peux payer en plusieurs fois ?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Oui. Oui. Et oui. Les appareils reconditionnés sont aussi performants que les neufs, tout en étant déjà moins chers. Et vous pouvez aussi profiter de ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://help.backmarket.com/hc/fr-fr/articles/360026643854-Quels-sont-les-moyens-de-paiement-accept%C3%A9s-sur-Back-Market',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'facilités de paiement',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value: ' sur Back Market.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '3fJsPjUsDqFP6CGco3ISoV',
            title:
              'Est-ce que je peux souscrire à une assurance pour mon appareil ?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Casse, oxydation, vol... on assure vos arrières, et vos appareils reconditionnés, avec notre assurance ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.fr/fr-fr/e/backup',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'BackUp',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value:
                        ". Sélectionnez l'offre qui vous convient lors de la validation de votre commande sur Back Market. Après tout, plus vous pouvez utiliser votre appareil longtemps, mieux c'est pour la planète.",
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'JSON_AND_HTML',
      },
    },
    {
      id: '3Dj6tHiZO8Gv7f0pRdbglr',
      type: 'ModularCardsCarousel',
      hiddenDevices: [],
      props: {
        title: 'Ils parlent de nous !',
        size: 'small',
        cardsHavePadding: true,
        layout: 'carousel',
        mobileScreenColumns: 1,
        cards: [
          {
            id: '67t4pwgvoLJfqqXm3ssMzU',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '5FLfstcfrhJM8eniDGZsp1',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/21v7AiHcgYgUSQqkRrzm1V/f96d1eef9191ab1b6ccbf34d957a8a8a/lesechos.jpg',
                        srcMobile: '',
                        alt: 'Logo Les echos',
                        width: 256,
                        height: 136,
                      },
                      link: {
                        href: 'https://www.lesechos.fr/industrie-services/conso-distribution/telephones-reconditionnes-back-market-vise-la-rentabilite-en-2024-2097869',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: 'LwBb5Lw2iMdIc2kcI7LDW',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '7zPdqJTJ81ykTMeAMLJnts',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/3lIGQWeQDVBIt0ypQAwoRS/1f2c2912f08f3d5e6fe0ff0d0b6d2b8c/le_parisien.jpg',
                        srcMobile: '',
                        alt: 'Logo Le Parisien',
                        width: 256,
                        height: 136,
                      },
                      link: {
                        href: 'https://cdn.reputation.onclusive.com/t/bCWFLrosl-ZTwBCuzDokW5s69NbSE5hQ-pdf-zC/Le_Parisien_20240522100000',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: '1s70WfR0pXG9JqXhEGycnm',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '4MvvLWTx913d75xLKYCzOe',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/1GvAWFdzCE8ISeUp79cBMn/b5c73a9c5405223e5c01c2e90bf9b661/capital.jpg',
                        srcMobile: '',
                        alt: 'Logo Capital',
                        width: 256,
                        height: 136,
                      },
                      link: {
                        href: 'https://www.capital.fr/conso/back-market-et-bouygues-vont-proposer-des-smartphones-reconditionnes-a-prix-casses-1497119',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: '7mTSguCCQ6M8JtzcSRyhpG',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '1DXTIcmCZMgpZr8gzxOjZ3',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5OoUeooG7UZbGRnH4cvTem/2a7db88daa71837812cc58135bdb9932/lsa.jpg',
                        srcMobile: '',
                        alt: 'Logo LSA',
                        width: 256,
                        height: 136,
                      },
                      link: {
                        href: 'https://www.lsa-conso.fr/back-market-lance-une-offre-de-recyclage-des-vieux-smartphones-avec-ecosystem,456162',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: '7qAsH7oKW1UajETZKMYrwH',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '5mG6DJc2bbufoJ8FgHYUtS',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/3mHFKPIrmjpHeXl0EorESX/8ccf98bb5262b0bf7b2da60d2fc7a65c/itr_news.jpg',
                        srcMobile: '',
                        alt: 'Logo ITR News',
                        width: 256,
                        height: 136,
                      },
                      link: {
                        href: 'https://itrnews.com/articles/201040/back-market-un-service-de-reprise-en-forte-croissance.html',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: 'Cm2VsrTvLFDQj3Sgu1JsQ',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '4JVKpPKU1FVS0w7wuqVvLd',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/NEvhaYeLsgqDzL9LqtOl5/75e1ccea160791d244a96e0e114f940e/LRDD.jpg',
                        srcMobile: '',
                        alt: 'Logo La revue du digital',
                        width: 256,
                        height: 136,
                      },
                      link: {
                        href: 'https://www.larevuedudigital.com/back-market-meilleur-site-e-commerce-francais-selon-les-internautes/',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
        ],
      },
    },
  ],
  hideFromSearchEngine: false,
}

export default HOME_FALLBACK_DATA
